/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/order */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-classes-per-file */
import React, { Suspense, lazy } from "react";

import { Switch, Route, BrowserRouter } from "react-router-dom";
import { withAuthentication } from "./components/sessions";
const PdfLayout = lazy(() => import("./PdfLayout"));
const PrivateLayout = lazy(() => import("./PrivateLayout"));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/pdf/" component={PdfLayout} />
        <Route path="/" component={PrivateLayout} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default withAuthentication(App);
